@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './editable-content';
@import './floating-vue.scss';
@import './cut-out-corner-card.scss';

.clickable-card {
  @apply border border-blue-gray-700 hover:border-blue-gray-500 hover:bg-opacity-50 focus:outline-none focus:ring-1 focus:ring-pv-light-blue;
}

.normal-flag {
  @apply rounded-md;
  margin: 0 !important;
}

.small-flag {
  @apply rounded;
}

.big-flag {
  @apply rounded;
}

.blockquote {
  @apply border-l-6 border-blue-gray-400 pl-6 whitespace-pre-line
}
